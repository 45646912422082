import React from "react";
import Layout from "../layout/Layout";
import { isUserLoggedIn } from "../utils/auth";
import Breadcrumbs from "../components/common/breadcrumbs/Breadcrumbs";
import { staticPage } from "../../config/staticPage";

const TrackOrdersPage = ({ location }) => {
  location.pathname = '/orders/track-orders'
  const user = isUserLoggedIn();
  const searchParams = new URLSearchParams(location.search);
  const orderId = searchParams.get("id");

  return (
    <>
      {user ? (
        <div className="page-wrapper">

          <div className="container">
            <Breadcrumbs location={location} />
          </div>

          <div className="page-heading-section">
            <div className="container">
              <h1 className="page-title">Track Order</h1>
            </div>
          </div>

          <div className="container">
            <div className="order-detail-tracking-section">
              <div>
                <iframe
                  src={`https://mw-order-tracking.netlify.app/?orderNumber=${orderId}`}
                  title="Order Tracking"
                />
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export const Head = () => {
  let login_nav = staticPage;
  let loginDetails = null;

  for (const obj of login_nav) {
    if (obj.name === "track-orders") {
      loginDetails = obj;
      break;
    }
  }
  return (
    <>
      <title>{loginDetails?.title}</title>
      <meta name="description" content={loginDetails?.description} />
    </>
  );
};

TrackOrdersPage.Layout = Layout;
export default TrackOrdersPage;
